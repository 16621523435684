import React from "react";

const AboutInfo = () => {
  return (
    <>
      <p>
        JAMBO Patch Guide started as a discussion between a few collectors (Adam
        McDaniel, Wiatt Williamson, and Russ Baker) after the launch of NOAC
        Patch Guide. We discussed the great work that was done by a fellow
        collector (Brian Ives) to have a PDF guide that showed people attending
        national scouting events like NOAC and National Jamboree and other
        collectors the different patches issued by Councils.
      </p>
      <p>
        We discussed while that was extremely valuable it didn’t allow for any
        updates or changes that take place. It also didn’t keep the data in a
        place that can be accessible for the future for everyone. Lastly, it
        also didn’t allow for a community aspect of patch collecting which
        allows all collectors to help keep collecting resources up to date.
      </p>
      <p>
        So, the idea was hatched for putting together a mobile optimized web app
        that allowed people to view patches by Council or by Year so they could
        see all the National Jamboree patches issued. The web app would also
        allow collectors to add patches to the app so each person could help
        improve the guide.
      </p>
      <p>
        Thanks to Russ Baker, Wiatt Williamson, and Brian Ives for their
        feedback on the site and providing several of the patches from their
        collection to fill out the site. We really appreciate their help.
      </p>
      <p>
        Mountain Shadow Enterprises owns and operates this site. We own several
        Scouting related websites like Eagle Peak Store and OA Council Store.
        Mountain Shadow Enterprises was built by Craig McDaniel. Craig was a
        great Scouter who believed in the Law and Oath of Scouting and the
        Obligation of the Order. He was an avid patch collector who wanted to
        provide resources to collectors that helped continue to grow the patch
        collecting community. This site is dedicated to his memory.
      </p>
    </>
  );
};

export default AboutInfo;
