import React, { useEffect, useState } from "react";
import { Button, message, Upload } from "antd";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import LodgeCard from "../../components/lodgeCard/LodgeCard";
import { LodgeCardsData } from "../../data/appdata";
import { LodgeArea } from "./lodge.style";
import { getPatches } from "../../utils/api";

const Lodge = () => {
  const [patches, setPatches] = useState([]);

  useEffect(() => {
    getPatches(setPatches);
  }, []);

  const props = {
    beforeUpload: (file) => {
      const isPDF = file.type === "/pdf";

      if (!isPDF) {
        message.error(`${file.name} is not a pdf file`);
      }

      return isPDF || Upload.LIST_IGNORE;
    },
  };
  return (
    <>
      <Header />
      <LodgeArea>
        <div className='lodgeHead'>
          <div>
            <p>Lodge # 1</p>
            <h4>Unami</h4>
          </div>
          <div>
            <h5>Search eBay</h5>
            <h5>Search OA Lodge Store</h5>
            <h5>Share</h5>
            <h5>Report Error</h5>
            <h5>Submit New Patch</h5>
            <h5>Back to Top</h5>
          </div>
        </div>
        <div className='lodgeContent'>
          {patches.map((item) => (
            <LodgeCard data={item} />
          ))}
        </div>
        <div style={{ textAlign: "end" }}>
          <Upload {...props}>
            <Button>
              Export to PDF
              <img src='/images/export-icon.svg' alt='click here' />
            </Button>
          </Upload>
        </div>
      </LodgeArea>
      <Footer />
    </>
  );
};

export default Lodge;
