import styled from "styled-components";

export const DropArea = styled.div`
  .ant-dropdown-trigger {
    display: inline-block;
    width: 188px;
    height: 50px;
    background-color: var(--MainColor);
    color: var(--LightColor);
    border-radius: 1.5625rem;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    line-height: 2;
    margin-right: 0.75rem;
    .ant-space {
      display: flex;
      justify-content: space-around;
      .ant-space-item {
        svg {
          width: 30px;
          height: 30px;
        }
      }
    }

    @media (max-width: 768px) {
      & {
        margin-right: 0;
      }
    }
  }
`;
export const MenuCheckbox = styled.div`
  .ant-checkbox-wrapper {
    font-weight: 600;
    align-items: baseline;
    display: flex;
    justify-content: space-between;
    .ant-checkbox {
      order: 1;
      .ant-checkbox-inner {
        border: 1px solid var(--MainColor);
        margin: 0 0.5rem;
        &::after {
          top: 44%;
          width: 6px;
          height: 10px;
        }
      }
    }
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: var(--MainColor) !important;
      }
      &::after {
        border: none;
      }
    }
    span:last-child {
      padding: 0;
    }
  }
`;
export const MenuBtn = styled.div`
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  text-align: end;
  z-index: 1050;
  background-color: var(--LightColor);
  padding-bottom: 0.25rem;
  a {
    background-color: var(--MainColor) !important;
    color: var(--LightColor) !important;
    border-color: var(--MainColor) !important;
    box-shadow: none !important;
    font-size: 0.875rem;
    font-weight: 600;
    width: 88px;
    height: 33px;
    border-radius: 1rem;
    margin-right: 1rem;
    display: inline-block;
    text-align: center;
    line-height: 2.5;
    text-decoration: none;
  }
`;
