import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import Header from "../../components/header/Header";
import { AboutArea } from "./about.style";
import Footer from "../../components/footer/Footer";
import SupportersInfo from "../../components/supporterInfo/SupportersInfo";
import { getSupporterContent } from "../../utils/api";
const About = () => {
  const { TabPane } = Tabs;
  const [suporterContent, setSupporterContent] = useState("");

  useEffect(() => {
    getSupporterContent(setSupporterContent);
    return () => {};
  }, []);

  console.log(suporterContent, "suporterContent");

  return (
    <>
      <Header />
      <AboutArea>
        <div className="aboutHead">
          <div>
            <h1>JAMBO Patch Guide Supporters</h1>
          </div>
        </div>
        <div className="aboutContent">
          <div className="container py-5 my-5">
            <SupportersInfo content={suporterContent} />
          </div>
        </div>
      </AboutArea>
      <Footer />
    </>
  );
};

export default About;
