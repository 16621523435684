import styled from "styled-components";

// Footer Open
export const Foot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0 1rem 1rem;
  background-color: var(--MainColor);
  div:last-child {
    width: 300px;
    text-align: center;
    @media (max-width: 575px) {
      width: 500px;
    }
  }
  p,
  a {
    margin-bottom: 0;
    color: var(--LightColor);
  }
  a {
    text-decoration: none;
    font-weight: 700;
    margin-right: 1.5rem;
    @media (max-width: 992px) {
      display: block;
      margin-bottom: 0.25rem;
    }
  }
`;
// Footer Close
