import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { Row, Col } from "react-bootstrap";
import Header from "../../components/header/Header";
import { ContactArea } from "./contact.style";

const ContactUs = () => {
  const [value, setValue] = useState("");
  const { TextArea } = Input;

  const handleFinish = (value) => {
    // setLoading(true);
    // addPatches(
    //   {
    //     image: image,
    //     comment: value.comment,
    //     lodge: value.lodge,
    //     year: value.year,
    //     status: "unapproved",
    //   },
    //   setLoading,
    //   reset
    // );
  };

  return (
    <>
      <Header />
      <ContactArea>
        <div className="aboutHead">
          <div>
            <h1>Contact US</h1>
          </div>
        </div>
        <div style={{ padding: "1.5rem" }}>
          <h2 className="text-center" style={{ marginBottom: "2em" }}>
            Send us a message.
          </h2>
          <Row style={{ alignItems: "center", justifyContent: "center" }}>
            {/* <Col lg={6}>
              <Form
                onFinish={handleFinish}
                name="contactForm"
                layout="vertical"
                initialValues={{
                  remember: true,
                }}
              >
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your name!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Subject"
                  name="subject"
                  rules={[
                    {
                      required: true,
                      message: "Please input your subject!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Your Message"
                  name="message"
                  rules={[
                    {
                      required: true,
                      message: "Please input your message!",
                    },
                  ]}
                >
                  <TextArea
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    autoSize={{
                      minRows: 8,
                      maxRows: 8,
                    }}
                  />
                </Form.Item>
                <div
                  style={{
                    textAlign: window.innerWidth < 768 ? "center" : "end",
                  }}
                >
                  <Button htmlType="submit">Submit</Button>
                </div>
              </Form>
            </Col> */}
            <Col lg={6}>
              <div className="contactInfo">
                <div>
                  <div>
                    <h4>Email Address:</h4>
                    <h4>
                      <a
                        href={`mailto:hello@jambopatchguide.com?subject=Contact Email`}
                      >
                        hello@jambopatchguide.com
                      </a>{" "}
                    </h4>
                  </div>
                  <div>
                    <h4>Address:</h4>
                    <h4>
                      Mountain Shadow Enterprises <br /> PO Box 24 <br />{" "}
                      American Fork, UT 84003
                    </h4>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </ContactArea>
    </>
  );
};

export default ContactUs;
