import React from "react";
import { FaqInfoArea } from "./faqInfo.style";

const FaqInfo = () => {
  return (
    <FaqInfoArea>
      <h4>Who can add Patches?</h4>
      <p>
        Anyone. That is what’s great about JAMBO Patch Guide. You don’t need to
        be an editor or only have a specific person from your Lodge list items.
        If you see a JAMBO Patch missing, add it. We will review your submission
        and once we approve it, it will be online for everyone to use.
      </p>
      <h4>Can I share images from this site to show my needs?</h4>
      <p>
        Absolutely. That is the idea. Take a screenshot or use the Share link to
        show people which patches you are missing.
      </p>
      <h4>There are errors with something listed. What should I do?</h4>
      <p>
        There is a link that says to Report an Error. Click on that and tell us
        what’s wrong and we will work to fix that issue. You can also just
        Contact Us if you want.
      </p>
      <h4>Can I still export this as a PDF?</h4>
      <p>
        Yes. If you want a PDF you can still do that. Just click on the Export
        PDF for either the Lodge or the Year and the app will generate a PDF for
        you to use.
      </p>
    </FaqInfoArea>
  );
};

export default FaqInfo;
