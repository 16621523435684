import React, { useEffect } from "react";
const HelpUs = () => {
  useEffect(() => {
    // window.initPayPalButton();
  }, []);
  return (
    <>
      <p>
        {" "}
        Patch Collectors are the reason we made this site. We made this so you
        can use this site to help with your collection, browse patch ideas, see
        what your Council has made historically or anything to help you have a
        more enjoyable time collecting patches.
      </p>
      <p>
        Without your help this site doesn’t grow. If you would like to support
        our efforts, here’s some specific ways to help.
      </p>
      <p>
        <b>Become a Supporter</b>.Look at your collection of patches and if you
        have any that belong on this guide please submit it to us. Supporters
        even get their name listed.
      </p>
      <p>
        <b>Post on Social Media</b>.Use the guide in your social media posts. If
        you are looking for a patch that is in the guide, screenshot the patch
        and post it on social media. Reference you found the patch on JAMBO
        Patch Guide with a link to our site.
      </p>
      <p>
        <b>Link to us from your site</b>. Adding a text link to our site from
        your site is a vote of confidence. It’s also a great way to share our
        resources with your visitors. Here’s some cut and paste linking
        information for your page:
      </p>
      <p>JAMBO Patch Guide (https://jambopatchguide.com/)</p>
      <p>The virtual guide for all things National Jamboree.</p>
      <p>
        Send a link to a friend. Share the site with one of your patch friends
        or Council members.
      </p>
      <p>
        <b>Show our Flyer at your Event</b>. Display our Table-Top Flyer at your
        next Scouting Event. Download the PDF here and print it out. You can
        place in your trading table so others can quickly scan the QR Code and
        use the site.
      </p>

      <img
        src={"/images/flyerImage.jpeg"}
        style={{ display: "block", width: "400px", margin: "2em auto" }}
      />
      <p>
        <b>Donate</b>.Hundreds of hours and thousands of dollars have done into
        creating this website. There are monthly fees for hosting and
        maintenance. If you would like you can donate and any donation will be
        directly used to offset the cost of keeping this website up and running.
      </p>

      {/* Paypal button */}
      <div id="smart-button-container">
        <div style={{ textAlign: "center" }}>
          <div style={{ marginBottom: "1.25rem" }}>
            <p>Select the level of Donation you would like to give.</p>
            <select id="item-options">
              <option value="Patch Novice" price="5">
                Patch Novice - 5 USD
              </option>
              <option value="Patch Beginner" price="10">
                Patch Beginner - 10 USD
              </option>
              <option value="Patch Expert" price="15">
                Patch Expert - 15 USD
              </option>
              <option value="Patch Master" price="20">
                Patch Master - 20 USD
              </option>
              <option value="Patch Grandmaster" price="25">
                Patch Grandmaster - 25 USD
              </option>
            </select>
            <select
              style={{ visibility: "hidden" }}
              id="quantitySelect"
            ></select>
          </div>
          {/* <button onClick={() => window.initPayPalButton()}>
            Pay with paypal
          </button> */}
          <div id="paypal-button-container"></div>
        </div>
      </div>
    </>
  );
};

export default HelpUs;
