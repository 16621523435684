import React, { useState, useEffect } from "react";
import { Form, Input, Select, Button, Upload, Checkbox, Row, Col } from "antd";
import Header from "../../components/header/Header";
import { SubmitArea } from "./index.styled";
import Footer from "../../components/footer/Footer";
import { getLodges, getYears, addPatches } from "../../utils/api";
import axios from "axios";
import ImageGuidelineModal from "../../components/imageGuideline";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import { useAuthValue } from "../../AuthContext";
import { Link } from "react-router-dom";

function parseQuery(queryString) {
  var query = {};
  var pairs = (
    queryString[0] === "?" ? queryString.substr(1) : queryString
  ).split("&");
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }
  return query;
}
const LoginScreen = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useAuthValue();

  if (currentUser) navigate("/");

  const handleFinish = async (values) => {
    setLoading(true);
    await auth
      .signInWithEmailAndPassword(values.email, values.password)
      .then(async (res) => {
        await res.user.updateProfile({
          displayName: values.name,
        });

        navigate("/");
      })
      .catch((err) => {
        message.error(err.message);
      });

    setLoading(false);
  };

  return (
    <>
      <Header />
      <div style={{ padding: "0 1.5rem" }}>
        <SubmitArea>
          <ImageGuidelineModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />
          <h2>Login to your JAMBO Patch Guide account</h2>

          <Row justify="center" style={{ height: "66vh" }}>
            <Col md={10}>
              <Form
                name="contactForm"
                layout="vertical"
                initialValues={{
                  remember: true,
                }}
                onFinish={handleFinish}
                form={form}
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input className="nameInput" />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input className="nameInput" type="password" />
                </Form.Item>
                <strong className="d-block" style={{ textAlign: "right" }}>
                  <a href="/forgot-password">Forgot Password ?</a>
                </strong>

                {/* <Checkbox required>
                  I agree to the terms and conditions of
                  <strong> NOAC Patch Guide.</strong>
                </Checkbox> */}
                <div className="submitBtn">
                  <Button htmlType="submit" loading={loading}>
                    Submit
                  </Button>
                </div>
                <Link to="/signup">
                  <a
                    style={{ color: "#443c76" }}
                    className="mt-5 text-center w-100 d-inline-block"
                    href=""
                  >
                    <strong>New to JAMBO Patch Guide? Sign up now.</strong>
                  </a>
                </Link>
              </Form>
            </Col>
          </Row>
        </SubmitArea>
      </div>
      <Footer />
    </>
  );
};

export default LoginScreen;
