import styled from "styled-components";

export const LodgeArea = styled.div`
  .lodgeHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 3rem 0;
    padding: 0 1.35rem;
    border: 1px solid var(--MainColor);
    p {
      font-size: 1.5rem;
      margin-right: 1rem;
    }
    h4 {
      font-weight: 600;
    }
    h5 {
      margin-left: 1.5rem;
      font-weight: 400;
    }
    p,
    h4,
    h5 {
      color: var(--MainColor);
      display: inline-block;
      margin-bottom: 0;
    }
  }
  .lodgeContent {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-wrap: wrap;
    @media (min-width: 1200px) {
      justify-content: space-between;
    }
  }
  .ant-upload {
    button {
      width: 288px;
      height: 64px;
      font-size: 1.25rem;
      font-weight: 600;
      background-color: var(--MainColor);
      border-color: var(--MainColor);
      color: var(--LightColor);
      box-shadow: none;
      border-radius: 3.125rem;
      padding: 0 2rem;
      margin: 0 1.35rem 5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 17px;
        height: 20px;
      }
    }
  }
`;
