import React from "react";
import { Tabs } from "antd";
import Header from "../../components/header/Header";
import { AboutArea } from "./about.style";
import Footer from "../../components/footer/Footer";
import AboutInfo from "../../components/aboutInfo/AboutInfo";
import PrivacyInfo from "../../components/privacyInfo/PrivacyInfo";
import TermsInfo from "../../components/termsInfo/TermsInfo";
import FaqInfo from "../../components/faqInfo/FaqInfo";
import HelpUs from "../../components/helpus/HelpUs";

const About = () => {
  const { TabPane } = Tabs;
  return (
    <>
      <Header />
      <AboutArea>
        <div className="aboutHead">
          <div>
            <h1>About JAMBO Patch Guide</h1>
          </div>
        </div>
        <div className="aboutContent">
          <Tabs tabPosition="left">
            <TabPane className="aboutInfo" tab="About" key="1">
              <AboutInfo />
            </TabPane>

            <TabPane className="aboutInfo" tab="Support Us" key="2">
              <HelpUs />
            </TabPane>
            <TabPane tab="Privacy Policy" key="3">
              <PrivacyInfo />
            </TabPane>
            <TabPane tab="Terms of Use" key="4">
              <TermsInfo />
            </TabPane>
            <TabPane style={{ marginTop: "5rem" }} tab="FAQ" key="5">
              <FaqInfo />
            </TabPane>
          </Tabs>
        </div>
      </AboutArea>
      <Footer />
    </>
  );
};

export default About;
