import React from "react";
import { Foot } from "./footer.style";
import moment from "moment"
const Footer = () => {
  return (
    <>
      <Foot>
        <div>
          <p>
            © JAMBO Patch Guide {moment().format("YYYY")}. All rights reserved. Photos and Content
            are © their respective owners. Personal, non-commercial use
            permitted.
          </p>
        </div>
        <div>
          <a href="#">Terms of Use</a>
          <a href="#">Privacy Policy</a>
        </div>
      </Foot>
    </>
  );
};

export default Footer;
