import React from "react";

const PrivacyInfo = () => {
  return (
    <>
      <h1 className="tabHeading">Privacy Policy</h1>
      <ol className="tabPara">
        <li>
          <strong>Acknowledgement and Acceptance of Terms:</strong>Mountain
          Shadow Enterprises (“MSE”) is committed to protecting your privacy.
          This Privacy Statement (“Statement”) sets forth our current privacy
          practices with regard to the information we collect when you interact
          with our website. By accessing the website at www.jambopatchguide.com
          (the “Site”), you acknowledge and fully understand this Statement and
          consent to the information collection and use practices described in
          this Statement. Other than as expressly set forth in this Statement,
          nothing in or omitted from this Statement limits MSE’s privacy
          practices.
        </li>
        <li>
          <strong>Information We Collect and How We Use It:</strong>The Site
          collects certain information from and about its users by, among other
          things, directly from our web server logs, from users, and with
          cookies.
        </li>
        <p>
          a. <strong>Web Server Logs</strong>
          When you visit, the Site may track information to administer the Site
          and analyze its usage. Examples of information the Site may track
          include but are not limited to:
        </p>
        <ul>
          <li>Your Internet protocol address.</li>
          <li>The kind of browser or computer you use.</li>
          <li>The devices you use</li>
          <li>Number of links you click within the Site.</li>
          <li>Location from which you accessed the Site.</li>
          <li>Date and time of your visit</li>
          <li>Name of your Internet service provider.</li>
          <li>Web page you linked to the Site from</li>
          <li>Pages you viewed on the Site.</li>
        </ul>
        <p>
          b. <strong>Use of Cookies:</strong>
          The Site may use Internet cookies to personalize or enhance your user
          experience. An Internet cookie is typically a small text file that is
          placed on your hard disk by a web server.
        </p>
        <p>
          One of the primary purposes of cookies is to provide a convenience
          feature to save you time. For example, if you personalize a web page,
          or navigate within a site, a cookie helps the site to recall your
          specific information on subsequent visits. Hence, this simplifies the
          process of delivering relevant content and eases site navigation by
          providing and saving your preferences and login information as well as
          providing personalized functionality.
        </p>
        <p>
          MSE reserves the right to share site statistics with partner
          companies.
        </p>
        <p>
          You may have the ability to accept or decline cookies. Most Web
          browsers automatically accept cookies, but you can usually modify your
          browser settings to decline cookies. If you reject cookies by changing
          your browser settings then be aware that this may disable some of the
          functionality on the Site.
        </p>
        <p>
          c. <strong>Personal Information from Users:</strong>
          You may also be asked to disclose personal information to the Site so
          that the Site can provide assistance and information to you. For
          example, such data may be warranted in order to provide online
          technical support and troubleshooting.
        </p>
        <p>
          MSE will not disclose personally identifiable information we collect
          from you to third parties without your permission except to the extent
          necessary including:
        </p>
        <ul>
          <li>
            To protect MSE from liability as MSE deems appropriate in its sole
            discretion.
          </li>
          <li>To respond to legal process or comply with law</li>
        </ul>
        <li>
          <strong>Use of Web Beacons or GIF Files:</strong>
          The Site may contain electronic images known as Web beacons –
          sometimes also called single-pixel gifs – that allow the Site to,
          among other things, count users who have visited those pages and
          deliver co-branded services. The Site may include Web beacons in
          promotional e-mail messages or newsletters to determine whether
          messages have been opened and acted upon.
          <span className="mt-2 d-block">
            Some of these Web beacons may be placed by third-party service
            providers to help determine the effectiveness of advertising
            campaigns or e-mail communications. These Web beacons may be used by
            these service providers to place a persistent cookie on your
            computer. This allows the service provider to recognize your
            computer each time you visit certain pages or e-mails and compile
            anonymous information in relation to those page views, which in turn
            enables MSE and our service providers to learn which advertisements
            and e-mails bring you to the Site and how you use the Site.
          </span>
        </li>
        <li>
          <strong>Children’s Issues</strong> The Site is not directed to
          children under 13 years of age, and children under 13 years of age
          shall not use the Site to submit any individually identifiable
          information about themselves.
        </li>
        <li>
          <strong>Changes to This Statement: </strong> MSE has the discretion to
          update this Statement from time to time. When MSE does so, MSE will
          also revise the “updated” date at the top of this page. MSE encourages
          you to periodically review this Statement to stay informed about how
          the Site is helping to protect the personal information the Site
          collects. Your continued use of the service constitutes your agreement
          to this Statement and any updates.
        </li>
        <li>
          <strong>Contacting JAMBO Patch Guide: </strong> If you have questions
          regarding this Statement, its implementation, failure to adhere to
          this Statement and/or the Site’s general practices, please contact
          JAMBO Patch Guide at hello@jambopatchguide.com or send your comments
          to: Mountain Shadow Enterprises, PO Box 24, American Fork, UT 84003
        </li>
      </ol>
    </>
  );
};

export default PrivacyInfo;
