import firebase from 'firebase/app'

import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/messaging'

const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
})

firebase.firestore().enablePersistence()

// export const auth = app.auth();
export const db = app.firestore()
export const storage = app.storage()
export const auth = app.auth()
let newMsg = null

if (firebase.messaging.isSupported()) {
  newMsg = firebase.messaging()
}

export const messaging = newMsg
// export const googleProvider = new firebase.auth.GoogleAuthProvider();

export const getToken = (currentUser) => {
  if (firebase.messaging.isSupported()) {
    if (messaging)
      return messaging
        .getToken({
          vapidKey:
            'BONpPpSStjG_w--c5EYGP9IjChZy2upKDpL4sodgUb6v3gG034RWsL_XJeWAOLtJrBH68krGsAaw2LS4qzI-xWg',
        })
        .then((currentToken) => {
          console.log(currentToken, 'token is')
          if (currentToken) {
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
            return currentToken
          } else {
            // shows on the UI that permission is required
            return false
          }
        })
        .catch((err) => {
          // console.log("An error occurred while retrieving token. ", err);
          // catch error while creating client token
        })
  }
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    if (messaging) {
      messaging.onMessage((payload) => {
        console.log(payload, 'payload is')
        resolve(payload)
      })
    }
  })

export default app
