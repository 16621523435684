import React, { useState, useEffect } from 'react'
import { Dropdown, Menu, Space, Checkbox, Button } from 'antd'
import { MdFilterListAlt } from 'react-icons/md'
import { DropArea, MenuCheckbox, MenuBtn } from '../dropdown.style'
import { NavLink } from 'react-router-dom'
import { getYears } from '../../../utils/api'

const SortDropdown = ({ setSortFilter, sortFilter }) => {
  const [years, setYears] = useState([])

  useEffect(() => {
    getYears(setYears)
  }, [])

  let items = []
  let tempSort = [
    { name: 'Lodge Number', val: 'number' },
    { name: 'Lodge Name', val: 'lodgeName' },
    { name: 'Council Name', val: 'name' },
  ]

  for (let i = 0; i < tempSort.length; i++) {
    items.push({
      label: (
        <MenuCheckbox
          onClick={(e) => {
            // e?.stopPropagation();

            sortFilter == tempSort[i].val
              ? setSortFilter('')
              : setSortFilter(tempSort[i].val)
          }}
        >
          <Checkbox checked={sortFilter == `${tempSort[i].val}`}>
            {tempSort[i].name}
          </Checkbox>
        </MenuCheckbox>
      ),
      key: `${tempSort[i].name}`,
    })
  }

  const menu = (
    <>
      <Menu items={items} />
    </>
  )

  return (
    <>
      <DropArea className='sortDrop'>
        <Dropdown
          placement='bottom'
          arrow={{
            pointAtCenter: true,
          }}
          overlay={menu}
          trigger={['click']}
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              Sort By
              <MdFilterListAlt />
            </Space>
          </a>
        </Dropdown>
      </DropArea>
    </>
  )
}

export default SortDropdown
