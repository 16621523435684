import styled from "styled-components";

// Header Open
export const Head = styled.div`
  .navbar {
    padding: 0 1rem;
    ${"" /* background-color: #d9d9d9; */}
    .navbar-brand {
      font-weight: 900;
      width: 333px;
      ${"" /* background-color: var(--MainColor); */}
      color: var(--LightColor);
      text-align: left;
      line-height: 1.02;
      @media (max-width: 575px) {
        width: 155px;
      }

      // img {
      //   width: 160px;
      //   height: 90px;
      // }
    }
    .navLinks {
      margin-left: auto;
      @media (max-width: 991px) {
        text-align: center;
        margin-top: 2rem;
      }
      a {
        font-size: 1.25rem;
        color: var(--MainColor);
        font-weight: 500;
        margin-left: 1rem;
        padding: 0 0.25rem;
        text-decoration: none;
        @media (min-width: 1200px) {
          margin-left: 3rem;
        }
        &:hover {
          color: black;
        }
        p {
          margin-bottom: 0;
        }
      }
      .active {
        color: black;
      }
    }
  }
`;
// Header Close
