import React from "react";
import Header from "../../components/header/Header";
// import { Row, Col } from "react-bootstrap";
import { Row, Col } from "antd";
import { Features, HeroArea, HomeArea } from "./home.style";
import Footer from "../../components/footer/Footer";
import { NavLink } from "react-router-dom";
import FeatureCard from "../../components/featureCard/FeatureCard";

const Home = () => {
  const data = [
    {
      image: "/images/laptop-icon.svg",
      heading: "Cross Platform",
      text: "Check out your Jamboree Patches on either your smartphone or computer. Our design is optimized for all devices۔",
    },
    {
      image: "/images/search-icon.svg",
      heading: "Search for your Needs",
      text: "You can easily find what you're looking for on eBay or Eagle Peak Store by using the menu located next to the Council name.",
    },
    {
      image: "/images/filter-icon.svg",
      heading: "Easy to Use Filtering",
      text: "Looking for something specific? Easily filter your search by Jamboree Year, Council, or both using our intuitive search and filtering features. Find exactly what you're looking for in no time.",
    },
    {
      image: "/images/community-icon.svg",
      heading: "Community Driven",
      text: "You can assist in the growth of the guide by submitting any missing patches. If a particular patch is not present in the guide, feel free to send us the relevant information, along with a picture of the patch, and we will make sure to add it. Your valuable contribution can help collectors identify their needs more efficiently",
    },
    {
      image: "/images/feedback-icon.svg",
      heading: "Open to Feedback",
      text: " If you find any errors or inaccuracies in the guide, please inform us. We're committed to keeping the guide dynamic and up-to-date, and your feedback helps us achieve that. Once we get your report, we'll quickly fix any issues and give you the most reliable information possible.",
    },
    {
      image: "/images/feature-export-icon.svg",
      heading: "Export to PDF",
      text: "Would you like to have access to the guide offline? You can filter it by Council or National Jamboree Year and then select the export option. We will create a PDF guide for you to download and use at your convenience.",
    },
  ];
  return (
    <>
      <Header />
      <div style={{ padding: '0 1.75rem' }}>
        <HeroArea>
          <Row className='heroRow'>
            <Col lg={12}>
              <div className='heroInfo'>
                <h1 className='mainHeading'>
                  Welcome to JAMBO Patch Guide <br />
                  <span className='secondaryHeading'>
                    The virtual guide for all things National Jamboree
                  </span>
                </h1>
              </div>
            </Col>
            <Col lg={12}>
              <div className='heroImage'>
                {/* <video
                  width="100%"
                  height="100%"
                  autoplay="autoplay"
                  muted
                  controls
                >
                  <source src="/promoVideo.mp4" type="video/mp4" />
                </video> */}
              </div>
            </Col>
          </Row>
        </HeroArea>

        <HomeArea>
          <div className='homeContent'>
            <div className='contentFooter guideButton'>
              <NavLink to='patches'>Enter Guide</NavLink>
            </div>
            <div className='contentHeading'>
              <h2>Features</h2>
            </div>
            <div className='contentInfo'>
              <p>
                JAMBO Patch Guide provides many features that help you review
                patches that are related to the National Jamboree. Here are some
                of the best features:
              </p>
            </div>
          </div>
          <Features>
            <Row gutter={[80, 22]}>
              {data.map((item) => (
                <Col xl={8} lg={12} xs={24}>
                  <FeatureCard data={item} />
                </Col>
              ))}
            </Row>
          </Features>
        </HomeArea>
      </div>
      <Footer />
    </>
  )
};

export default Home;
