import styled from "styled-components";

export const ContactArea = styled.div`
  .aboutHead {
    background-image: url("/images/Banner.jpg");
    width: 100%;
    height: 333px;
    text-align: center;
    margin-bottom: 2em;
    div {
      background: linear-gradient(
        90.05deg,
        rgba(36, 0, 255, 0.58) 0.06%,
        rgba(36, 0, 255, 0) 106.91%
      );
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      h1 {
        font-size: 6.25rem;
        font-weight: 900;
        color: var(--LightColor);
        @media (max-width: 575px) {
          font-size: 3.25rem;
        }
      }
    }
  }
  h1 {
    font-size: 6.25rem;
    font-weight: 900;
    margin-bottom: 0.75rem;
    @media (max-width: 575px) {
      font-size: 3.55rem;
    }
  }
  h2 {
    font-weight: 700;
  }
  h1,
  h2 {
    color: var(--MainColor);
  }
  .ant-form {
    position: relative;
    padding-left: 0;
    .ant-form-item {
      margin-bottom: 1rem;
      .ant-form-item-required {
        font-size: 1.25rem;
        font-weight: 500;
        color: var(--MainColor);
        &::before {
          content: "" !important;
        }
      }
      .ant-form-item-control-input {
        input {
          height: 47px;
        }
        input,
        textarea {
          background-color: rgba(36, 0, 255, 0.15) !important;
          border: 1px solid var(--MainColor) !important;
          box-shadow: none !important;
        }
      }
    }
    button {
      width: 230px;
      height: 64px;
      border-radius: 3.125rem;
      font-size: 1.5rem;
      font-weight: 600;
      background-color: var(--MainColor);
      border-color: var(--MainColor) !important;
      color: var(--LightColor);
      box-shadow: none !important;
    }
  }
  .contactInfo {
    width: 100%;
    max-width: 533px;
    height: 444px;
    margin: 0 auto;
    background-color: rgba(36, 0, 255, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    @media (max-width: 992px) {
      margin-top: 2rem;
      max-width: 100%;
    }
    h4 {
      color: var(--MainColor);
      font-weight: 600;
    }
    h4:first-child {
      margin-bottom: 0;
      position: relative;
      display: inline-block;
      &::after {
        content: "";
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: var(--MainColor);
      }
    }
    div {
      div:first-child {
        margin-bottom: 2.5rem;
      }
    }
  }
`;
